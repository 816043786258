.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.itemmodel {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background-color: white !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  width: 40% !important;
  transform: translate(-50%, -50%);
  border: 1px solid lightgray !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.itemmodelnew {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background-color: white !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  width: 35% !important;
  transform: translate(-50%, -50%);
  border: 1px solid lightgray !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dropdown {
  position: relative;
  width: 6px;
  height: 6px;

}

.dropdown_list {
  position: absolute;
  top: 50px;
  right: -0.2px;
  font-size: 15px;
  border: 1px solid gray;
  padding: 5px 10px 10px 20px;
  background-color: white;
  border-radius: 10px;

}

.dropdown_list ul li {
  border-bottom: 1px solid gray;

}

.h-screen {
  height: 91vh;
  overflow: scroll;
}

.h-screen::-webkit-scrollbar {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  float: right;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.footerdiv {
  position: absolute;
  bottom: 10px;
}

.dashboardcard {
  align-items: center;
  display: grid;
}

.logindiv {
  height: 100vh;
}

.eyeicon {
  float: right;
  margin-top: -38px;
  position: relative;
  z-index: 2;
  left: 136px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  background: transparent;
  /* border: 3px solid #3c3c3c; */
  border-radius: 50%;
  text-align: center;
  line-height: 250px;
  font-family: sans-serif;
  font-size: 20px;
  color: #338aec;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #338aec;
  box-shadow: 0 0 2px rgba(0, 0, 0, .5);
}

.ring:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #338aec;
  border-right: 3px solid #338aec;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

.spantag {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

.spantag:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #338aec;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px #338aec;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.doneimg {
  width: 20%;
}

.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: #66666682;
  /* opacity: 0.8; */
  z-index: 998;
  height: 100%;
  width: 100%;
  }
  .overlay-box {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white;
  /* background: #666666; */
  opacity: .8;
  z-index: 1000;
  text-align: center;
  }
  .inpfeed {
  color: #666;
  font-size: 12px;
  }